<template>
  <div>
		<m-header class="y-center ptb-xs">
      <span class="size-md bolder">{{id?'编辑活动':'新增活动'}}</span>
    </m-header>
		<div class="edit_cell">
			<el-card class="box-card edit_left">
				<el-form ref="form" :model="form" label-width="80px">
					<el-form-item label="活动标题">
						<el-input v-model="form.title"></el-input>
					</el-form-item>
					<el-form-item label="活动内容">
						<el-input type="textarea" :rows="12"  placeholder="请输入内容" v-model="form.content"></el-input>
					</el-form-item>
					<el-form-item label="上传图片:">
						<m-uploader type="single" :file.sync="form.imageUrl"></m-uploader>
					</el-form-item>
					<el-form-item label="报名日期:">
						<el-date-picker
							v-model="form.registrationTime"
							type="datetimerange"
							range-separator="至"
							start-placeholder="开始日期"
							end-placeholder="结束日期">
						</el-date-picker>
					</el-form-item>
					<el-form-item label="活动日期:">
						<el-date-picker
							v-model="form.eventTime"
							type="datetimerange"
							range-separator="至"
							start-placeholder="开始日期"
							end-placeholder="结束日期">
						</el-date-picker>
					</el-form-item>
					<el-form-item label="活动地址:">
						<el-input v-model="form.address"></el-input>
					</el-form-item>
					<el-form-item>
						<el-button @click="showLocation = true" type="info" style="margin-top: 20rpx">获得定位点</el-button>
									<el-button @click="deleteStep()" type="danger">删除</el-button>
					</el-form-item>
					<el-form-item label="经度:">
						<el-input v-model="form.longitude"></el-input>
					</el-form-item>
					<el-form-item label="经度:">
						<el-input v-model="form.latitude"></el-input>
					</el-form-item>
					<el-form-item label="参与人数:">
						<el-input v-model="form.count"></el-input>
					</el-form-item>
				</el-form>


				<Modal
					v-model="showLocation"
					width="80%"
					@on-ok="setCurrentStepLocation"
					title="获得定位点"
				>
					<lat-lng :location="location"></lat-lng>
				</Modal>
			</el-card>
			<el-card class="right" >
				<el-form ref="obj" :model="obj" label-width="160px">
					<el-form-item label="是否置顶该活动：">
						<el-switch v-model="obj.isTop"></el-switch>
					</el-form-item>
					<el-form-item label="上架：">
						<el-switch v-model="obj.status"></el-switch>
					</el-form-item>
					<el-form-item label="推荐至首页Banner：">
						<el-switch v-model="obj.bannerShowFlag"></el-switch>
					</el-form-item>
					<el-form-item>
						<el-button @click="cancel">取消</el-button>
						<el-button type="primary" @click="onSubmit">保存</el-button>
					</el-form-item>
				</el-form>
			</el-card>
		</div>
	</div>
</template>

<script>
import {addEvent, getDetail, updateEvent} from '@/axios/api/activity.js'
import LatLng from "@/common/LatLng.vue";
export default {
	components: {
    LatLng,
  },
	data() {
		return {
			form: {
				title: '', //标题
				content: '', //内容
				imageUrl: '', //图片
				eventTime: '', //活动日期
				address: '', //具体活动地址
				longitude: '',//经度
				latitude: '', //维度
				count: '', //参与人数
				registrationTime: '', //报名时间
			},
			showLocation: false,
			location: {},
			//1-是， 0-否
			obj: {
				isTop: true,
				status: true, 
				bannerShowFlag: true,
			}
		}
	},
	created() {
    this.id = this.$route.query.id || null;
		if (this.id) {
			this.getData();
		}
		
	},
	methods: {
		deleteStep() {
			this.form.address= ''
			this.form.longitude= ''
			this.form.latitude= ''
		},
	setCurrentStepLocation() {
      this.form.address = this.location.name;
      this.form.latitude = this.location.latitude;
      this.form.longitude = this.location.longitude;
    },
		//
		async onSubmit() {
			let data = this.dealData()
			if (this.isValid(data)) {
				if(this.id) {
					data.id = this.id
					this.updateData(data);
				} else {
					
					let res = await addEvent(data)
					if (res.status == 1) {
						this.$router.push("./list");
					}
					console.log(res);
				}
			}
			 
		},
		cancel() {
			this.$router.push({ path: "/activity/list",});
		},
		//修改活动
		async updateData(data) {
			let res = await updateEvent(this.id,data)
			this.$router.push("./list");
		},
		//获取详情
		async getData( ) {
			let res = await getDetail({id: this.id})
			if (res.status == 1) {
				let data = res.data
				this.form.title = data.title
				this.form.content = data.content
				this.form.imageUrl = data.imageUrl
				this.form.address = data.address
				this.form.longitude = data.longitude
				this.form.latitude = data.latitude
				this.form.count = data.count
				this.form.eventTime = [data.eventStartTime, data.eventEndTime]
				this.form.registrationTime = [data.registrationStartTime, data.registrationEndTime]
				this.obj.isTop = data.isTop == 1
				this.obj.status = data.status == 1
				this.obj.bannerShowFlag = data.bannerShowFlag == 1
			}
		},

		fromateTime(d) {
			return new Date(d).getTime()
		},
		
		isValid(obj) {
      if (!obj.title) {
        this.$message.info("标题不能为空");
        return false;
      }
      if (!obj.content) {
        this.$message.info("内容不能为空");
        return false;
      }
      if (!obj.imageUrl) {
        this.$message.info("封面不能为空");
        return false;
      }
			if (!obj.registrationStartTime) {
        this.$message.info("报名日期不能为空");
        return false;
      }
      if (!obj.eventStartTime) {
        this.$message.info("活动日期不能为空");
        return false;
      }
			 
			if (!obj.address) {
        this.$message.info("地址不能为空");
        return false;
      }
			if (!obj.longitude) {
        this.$message.info("请在地图上选择地址");
        return false;
      }
   
      return true;
    },

		dealData() {
		  let 	user = JSON.parse(localStorage.getItem('user'));
			
			let data = {

				creatorId: user.id,
				eventStartTime : this.fromateTime(this.form.eventTime[0]),
				eventEndTime : this.fromateTime(this.form.eventTime[1]),
				registrationStartTime : this.fromateTime(this.form.registrationTime[0]) ,
				registrationEndTime : this.fromateTime(this.form.registrationTime[1]),
				isTop : this.obj.isTop ? '1': '0',
				status : this.obj.status ? '1': '0',
				bannerShowFlag : this.obj.bannerShowFlag ? '1': '0',
				title: this.form.title, //标题
				content: this.form.content, //内容
				imageUrl: this.form.imageUrl, //图片
				address: this.form.address, //具体活动地址
				longitude: this.form.longitude,//经度
				latitude: this.form.latitude, //维度
				count: this.form.count, //参与人数
			}
			return data
		}
	}

}
</script>

<style lang="less">
.box-card {
	// width: 68%;
	// margin-right: 2%;
	display: inline-block;
}

.edit_left {
	flex: 1;
	margin-right: 10px;
}

.right {
    width: 380px;
    height: 280px;
    background: #FFFFFF;
    border-radius: 2px 2px 2px 2px;
    margin-left: 10px;
}

.right .box-card {
  width: 100%;
}

.edit_cell {
	display: flex;
}
</style>
